<template>
  <b-card>
    <div>
      <b-row>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-success"
          class="btn-icon rounded-circle ml-1"
          @click="showSearch = false"
          v-show="showSearch"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-warning"
          class="btn-icon rounded-circle ml-1"
          @click="showSearch = true"
          v-show="!showSearch"
        >
          <feather-icon icon="XIcon" />
        </b-button>
        <b-col v-show="!showSearch">
          <b-form-input
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            size="sm"
            type="search"
          />
        </b-col>
      </b-row>
    </div>
    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
    <b-table-simple
      striped
      stacked="md"
      class="mt-2 responsive text-center"
      id="tableElderlys"
      :item="fetchProfessionalSuggests"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <b-thead>
        <b-tr>
          <b-th>Nome</b-th>
          <b-th>Contato</b-th>
          <b-th>Observação</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr :key="indextr" v-for="(tr, indextr) in professionalSuggests">
          <b-td>{{tr.name}}</b-td>
          <b-td>{{tr.contact}}</b-td>
          <b-td>{{tr.observation}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-pagination
      @change="handlePageChange"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="totalRows"
      aria-controls="tableElderlys"
      class="mt-1"
    />
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      showSearch: true,
      isLoading: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,

    };
  },
  computed: {
    ...mapState("professionalSuggests", ["professionalSuggests", "pagination"]),
  },
  methods: {
    fetchProfessionalSuggests(pagination = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("professionalSuggests/fetchProfessionalSuggests", {
          pagination: pagination,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handlePageChange(value) {
      this.fetchProfessionalSuggests(true, value);
    },

  },
  created() {
    this.fetchProfessionalSuggests();
  },
  watch: {
     search(val) {
      this.currentPage = 1;
      this.fetchProfessionalSuggests(true, 1);
    },

  },
};
</script>

<style>
</style>
